import React from "react"
import { Row, Col, Container, Image } from "react-bootstrap"
import LazyLoad from "react-lazyload"
import Layout from "../components/layout"
import SEO from "../components/seo"
// import Countdown from 'react-countdown'

export default function ExpiredPage() {
  return (
    <>
      <Layout pageInfo={{ pageName: "Emotional Mastery" }}>
        <SEO
          title="Emotional Mastery"
          keywords={[`Emotional Mastery`, `Emotional`, `Mastery`]}
        />
        <LazyLoad>
          <section className="bg-expired-offer">
            <Container>
              <Row className="center-height-flex">
                <Col lg={12}>
                  <div className="__em-logo-lc">
                    {/* set logo love coach */}
                    <div className="d-flex justify-content-center">
                      <Image
                        className="img-fluid"
                        src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-lc.png"
                      />
                    </div>
                    {/* end set logo love coach */}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="text-expired-offer">
                    <h2>Penawaran Telah Berakhir</h2>
                  </div>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <div className="__em-logo-emotional">
                    <Image
                      className="img-fluid"
                      src="https://joseaditya.sgp1.cdn.digitaloceanspaces.com/images/lovetraining.id/emotional-mastery/logo-em.png"
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </LazyLoad>
      </Layout>
    </>
  )
}
